<template>
	<div class="layout">
		<div style=""><Nav></Nav></div>
		<div class="view">
			<keep-alive include="Webdav">
				<router-view />
			</keep-alive>
		</div>
	</div>
</template>

<script>
import Nav from '~/frame/nav'
export default {
  mounted () {},
  methods: {},
  components: { Nav }
}
</script>

<style>
.layout { display: flex; width: 100vw; }
.view { width: 100%; }

</style>