<template>
	<div class="flex" style="height: 100%">

    <div v-if="isMobile" style="width: 100vw; background-color: #212121; display: flex; height: 64px; color: #ffffff; justify-content: center; align-items: center;">
      <div v-for="(item, index) in navMobile" >
					<div  :class="[item.name === $route.name ? 'nav-actived' : 'nav-not-actived']" style="user-select: none; padding: 16px; display: flex; align-items: center; ">
						<elt-icon :size="20" :icon="item.icon"></elt-icon>
					</div>
				</div>
    </div>

		<div v-else class="nav-bar" :class="[this.fold ? 'nav-bar-max' : 'nav-bar-min']">
			<div style="height: 91px;">
				<div @click="unfold" style="user-select: none; display: flex; justify-content: flex-end" >
					<elt-icon :size="20" icon="icon-collapse" style="color: #ffffff;"></elt-icon>
				</div>
				<div style="margin-top: 44px; display: flex; align-items: center; height: 29px;">
					<elt-icon :size="24" icon="icon-figma" style="color: #ffffff;"></elt-icon>
					<div v-show="fold" style="color: #ffffff; font-size: 24px; margin-left: 24px;">扬灵兮</div>
				</div>
			</div>

			<div style="height: calc(100vh - 91px - 66px - 32px - 100px); padding: 50px 0;">

				<div v-for="(item, index) in nav">
					<div @click="push(item.name)" :class="[item.name === $route.name ? 'nav-actived' : 'nav-not-actived']" class="nav-item">
						<elt-icon :size="item.name === $route.name ? 21 : 22" :icon="item.icon"></elt-icon>
						<span style="margin-left: 20px;" v-show="fold">{{item.title}}</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- <div v-else class="nav-bar-min nav-bar">
			<div style="height: 91px;">
				<div @click="unfold" style="user-select: none; display: flex; justify-content: center" >
					<elt-icon :size="22" icon="icon-expand" style="color: #ffffff;"></elt-icon>
				</div>
				
				<div style="margin-top: 44px; display: flex; align-items: center; height: 29px; justify-content: center;">
					<elt-icon :size="24" icon="icon-figma" style="color: #ffffff;"></elt-icon>
				</div>
			</div>
			<div style="height: calc(100vh - 91px - 66px - 32px - 100px); padding: 50px 0;">

				<div @click="push(item.name)" v-for="(item, index) in nav">
					<div @click="push(item.name)" :class="[item.name === $route.name ? 'nav-actived' : 'nav-not-actived']" class="nav-item">
						<elt-icon :size="22" :icon="item.icon"></elt-icon>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import EltIcon from '~/elt-icon'
import { whetherMobile } from '@/utils/status'

export default {
	data() {
		return {
			nav: [
				{"title": "首页", "name": "Home", "icon": "icon-home"}, 
				{"title": "Webfont", "name": "Font", "icon": "icon-italics"},
				{"title": "浏览器书签", "name": "Fav", "icon": "icon-bookmark"},
				{"title": "字体图标", "name": "Icon", "icon": "icon-hash"},
				{"title": "壁纸 & 插画", "name": "Wall", "icon": "icon-window"},
				{"title": "Webdav", "name": "Dav", "icon": "icon-server"},
				// {"title": "脚本", "name": "Bash", "icon": "icon-keyboard"},
			],

      navMobile: [
				{"title": "首页", "name": "Home", "icon": "icon-home"}, 
				{"title": "Webfont", "name": "Font", "icon": "icon-italics"},
				{"title": "浏览器书签", "name": "Fav", "icon": "icon-bookmark"},
				{"title": "字体图标", "name": "Icon", "icon": "icon-hash"}
			],
			fold: false,
      isMobile: whetherMobile(),
		}
	},
	components: {
		EltIcon,
	},

	created() {},

	methods: {
		push(name) { this.$router.push({ name: name }) },
		unfold() { this.fold = !this.fold }
	}
}
</script>

<style>
.nav-actived { background-color: #36373A; color: #ffffff; font-weight: 700; }
.nav-not-actived { color: #727277; }
.nav-item {user-select: none; margin-top: 8px; padding: 16px; border-radius: 12px; display: flex; align-items: center; }

.nav-bar {
	/* background-color: #2C2D31; */
  background-color: #212121;
	display: flex; padding:20px; flex-direction: column; justify-content: space-between;
	/* border-right: 3px solid #020202; */
	/* min-width: 220px; */
	/* box-shadow: 8px 0 8px #1E1E1E; */
}

.nav-bar-min {
	width: 54px;
}


.nav-bar-max {
	width: 220px;
} 
</style>